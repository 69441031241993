
import {
    defineComponent, ref
} from 'vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import router, { propertyBaseRouter, propertySubRouter } from '@/router';
import setAccess from '@/components/view/pm/set-access';

export default defineComponent({
    components: {
        propertyBreadCrumb,
        setAccess
    },
    props: {
        id: {
            type: String
        }
    },
    setup(props) {
        const breadCrumbHeaders = [
            {
                label: WordList.ProperAllTextAccessGroup,
                path: `/${propertyBaseRouter}/${propertySubRouter.access}`
            },
            {
                label: props.id === undefined ? WordList.ProperAllTextNew : WordList.TabelConfigInHtmlModify
            }
        ];

        const commit = ref(0);

        const back = () => {
            if (props.id) router.push(`/${propertyBaseRouter}/${propertySubRouter.accessInfo}?id=${props.id}`);
            else router.push(`/${propertyBaseRouter}/${propertySubRouter.access}`);
        };

        return {
            breadCrumbHeaders,
            commit,
            back
        };
    }
});
